
/************Contenedor del Header****************/
.header-container {
    height: 120px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; /* Permite que los elementos pasen a la siguiente línea */
    height: auto;
  }
  /*****header-burger******/
  .header-container-burger {
    margin-top: 20px;
    padding-left: 25px;
    display: flex;
    align-items: center;
    width: 260px;
    transition: 0.5s all ease;
    border-radius: 0px 10px 10px 0px;
    font-size: 25px;
    font-weight:lighter;
    color: #b0bdd1;
  }
  
  .header-container-burger:hover {
    background: linear-gradient(to right, #153d77, #3663a6, #9ab1d3);
    cursor: pointer;
    width: 265px;
    padding-left: 30px;
  
  }
  .header-container-burger:hover ,
  .header-container-burger:hover .header-burger-text {
    color: #fff;
  }
  
  .header-burger-text {
    margin: 4px 0px 0px 10px;
    font-size: 15px;
    font-weight: lighter;
    color: #b0bdd1;
    font-family: Roboto;
  
  }
  
  .header-burger-ico {
    font-size: 25px;
    font-weight: lighter;
    color: #b0bdd1;
  }
  
  /******header-login******/
  .header-container-login {
    width: 100%;
    transition: 0.5s all ease;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-right: 5px;
    align-items: center;
    width: 50px;
    padding: 5px;
    border-radius: 5px;
    height: 60px;
    font-size: 22px;
    font-weight: lighter;
    color: #b0bdd1;
  }
  
  .header-container-login:hover {
    color: #fff;
    cursor: pointer;
    width: 90px;
    background: linear-gradient(to right, #153d77, #6691d0, #fff);
  }
  

  
  .header-login-text {
    font-size: 13px;
    font-weight: lighter;
    color: #b0bdd1;
    font-family: Roboto;
  }
  .header-container-login:hover .header-login-text,
  .header-container-login:hover .header-login-icon {
    color: #fff;
  }
  
  /******header-welcome******/
  .header-container-welcome {
    display: flex;
    margin-top: 5px;
    margin-left: 25px;
    cursor: pointer;
  }
  
  
  .header-welcome-logo {
    height: 30px;
    width: 30px;
  }
  
  .header-welcome-text {
    font-size: 20px;
    font-weight: lighter;
    color: #fff;
    font-family: Roboto;
    text-align: center;
    margin: 0%;
  }
  /**********************************************Escritorio*************************************************/
/*************Navbar*********************/
@media (min-width: 992px) {
    .header-container-welcome {
      padding-left: 40px;
      margin-top: 30px; 
      align-items: center;
    }
  
    .header-welcome-logo {
      height: 40px;
      width: 40px;
    }
  
    .header-welcome-text {
      font-size: 25px;
    }
  
    .header-container {
      height: 200px;
    }

   /*burger*/
   .header-container-burger:hover {
    width: 380px;
    padding-left: 70px;
  }   
    .header-container-login {
        margin-right: 70px;
  }
  

  
  }