/********Tabla General************/



.header-table-region-caleta-flota-flota {
    height: 38px;
    background-color: #153d77;
    color: white;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 18px;
    font-weight: lighter;
    font-family: Roboto;
}

.header-table-region-caleta-select-flota-flota {
    height: 38px;
    background-color: rgb(17, 122, 40);
    color: white;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 18px;
    font-weight: lighter;
    font-family: Roboto;
}


.table-region-caleta-flota-flota {
    margin-top: 10px;
    box-shadow: 15px 15px 15px rgb(69, 106, 149) !important;
    border: 1px solid rgb(69, 106, 149) !important;
    margin-left: 0px;
    border-radius: 5px;
    width: 100% !important;
    max-height: 586px !important;
    border-radius: 5px;
    height: 423px !important;
}

.table-info-region-caleta-flota-flota tr:hover {
    background-color: #ff0707;
    /* Color de fondo cuando se hace hover */
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.table-info-region-caleta-flota-flota tr:hover .MuiTableCell-root {
    color: #fff;
    /* Asegura que todas las celdas cambien de color con el hover */
    font-style: oblique;
}

.text-data-table-flota-flota {
    text-align: right !important;
}

/* Excluye la fila total del hover */
.table-info-region-caleta-flota-flota tr.no-hover:hover {
    background-color: #153d77;
    color: inherit;
    /* Mantén el color del texto predeterminado */
    cursor: auto;
}

.table-info-region-caleta-flota-flota tr.no-hover:hover {
    background-color: #153d77;
    color: inherit;
    /* Mantén el color del texto predeterminado */
}

.table-info-region-caleta-flota-flota thead tr:hover {
    background-color: #1954ac;
    ;
    /* No cambia el fondo del encabezado */
    color: inherit;
    /* Mantén el color del texto original del encabezado */
}


.table-region-caleta-flota-flota thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
    /* O el color de fondo que desees */
}

.table-info-region-caleta-flota-flota {
    width: 100%;

    margin-top: 5px;
}

.title-column-flota-flota {
    background-color: #1954ac;
    height: 50px !important;
}

.text-title-column-flota-flota {
    color: #fff !important;
}

.table-total-region-caleta-flota-flota {
    background-color: #153d77;
}

.table-total-text-region-caleta-flota-flota {
    color: white !important;
}

.table-info-flota-flota .MuiTableRow-root {
    height: 50px;
    /* Ajusta este valor según la altura deseada */
}

/* Ajusta el padding dentro de las celdas para modificar la altura de las filas */
.table-info-region-caleta-flota-flota .MuiTableCell-root {
    padding: 0px 15px 0px 15px;
    /* Ajusta este valor según el padding deseado */
}

/**********************************************Escritorio*************************************************/
@media (min-width: 992px) {


    .table-region-caleta-flota-flota {
        width: 37.7vw !important;
        border-radius: 5px;

    }

    .table-info-region-caleta-flota-flota {
        width: 100% !important;
    }
}