/********Tabla General************/



.header-table-zone-caleta {
    height: 38px;
    background-color: #153d77;
    color: white;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 18px;
    font-weight: lighter;
    font-family: Roboto;
    width: auto !important;
    margin-left: 0px;
}

.header-table-zone-caleta-select {
    height: 38px;
    background-color: rgb(17, 122, 40);
    color: white;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 18px;
    font-weight: lighter;
    font-family: Roboto;
}


.table-zone-caleta {
    box-shadow: 15px 15px 15px rgb(69, 106, 149) !important;
    border: 1px solid rgb(69, 106, 149);
    margin-left: 0px;
    margin-top: 10px;
    border-radius: 5px;
    width: 100%;
    height: 420px;
    box-sizing: border-box;
    /* Asegura que el padding esté incluido en el ancho total */
}

.text-data-table {
    text-align: right !important;
}

.table-info-zone-caleta tr:hover {
    background-color: #ff0707;
    /* Color de fondo cuando se hace hover */
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.table-info-zone-caleta tr:hover .MuiTableCell-root {
    color: #fff;
    /* Asegura que todas las celdas cambien de color con el hover */
    font-style: oblique;
}

/* Excluye la fila total del hover */
.table-info-zone-caleta tr.no-hover:hover {
    background-color: #153d77;
    color: inherit;
    /* Mantén el color del texto predeterminado */
    cursor: auto;
}

.table-info-zone-caleta tr.no-hover:hover {
    background-color: #153d77;
    color: inherit;
    /* Mantén el color del texto predeterminado */
}

.table-info-zone-caleta thead tr:hover {
    background-color: #1954ac;
    ;
    /* No cambia el fondo del encabezado */
    color: inherit;
    /* Mantén el color del texto original del encabezado */
}


.table-zone-caleta thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
    /* O el color de fondo que desees */
}

.table-info-zone-caleta {
    width: 100%;
    margin-top: 5px;
}

.title-column {
    background-color: #1954ac;
    height: 50px !important;

}

.text-title-column {
    color: #fff !important;
}

.table-total-zone-caleta {
    background-color: #153d77;
}

.table-total-text-zone-caleta {
    color: white !important;
}

.table-info .MuiTableRow-root {
    height: 50px;
    /* Ajusta este valor según la altura deseada */
}

/* Ajusta el padding dentro de las celdas para modificar la altura de las filas */
.table-info-zone-caleta .MuiTableCell-root {
    padding: 0px 15px 0px 15px;
    /* Ajusta este valor según el padding deseado */
}

/**********************************************Escritorio*************************************************/
@media (min-width: 992px) {

    .table-zone-caleta {
        width: 41.9vw !important;
        border-radius: 5px;
        margin-left: 15px;
    }


}