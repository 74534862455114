.icon-floating-menu-delete-resource {
  cursor: pointer;
  color: blue;
  /* Cambia el color según prefieras */
}

.icon-floating-menu-delete-resource:hover {
  color: red !important;
  /* Color al pasar el mouse */
}

.text-ia-resource {
  margin-left: 5px;
}

.floating-menu-resource {
  margin-top: 150px;
}
.container-button-resource{
  display: flex;
  align-items: center;
  align-content: center;
  margin-top: 20px;
  margin-left: 0px;
  padding: 8px;
  width: 70%;
  transition: 0.5s all ease;
  border-radius: 0px 20px 20px 0px;
  border-left: 2px solid #153d77;
  background: linear-gradient(to right, #153d77, #6691d0, #abc5ec);
  color: white;
}
.container-button-resource:hover {
  padding-left: 30px;
  width: 75%;
  background: linear-gradient(to right, #c01c1c, #f93e3e, #ecabab);
  box-shadow: 5px 5px 5px 5px rgb(69, 106, 149);
  cursor: pointer;
  border-left: 2px solid #ff0000;
}

/* ===== MEDIA QUERIES SOLO CAMBIOS EN 992PX O MÁS ===== */
@media (min-width: 992px) {
  .floating-menu-resource {
    margin-top: 0px;
  }
}