.container-resource-select-screens{
    width:100%;
     display: flex;
     flex-direction: column;
    
    }
.container-resource-info{
margin-left: 0px;
}    
.container-resource-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.container-resource-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
}   
.container-resource-3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
}   
.container-resource-4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
}   
.container-resource-5 {
    display: flex;
    flex-direction: column;
    justify-content: center;
}  
.container-resource-6 {
    display: flex;
    flex-direction: column;
    justify-content: center;
}    

.container-resource-7 {
    display: flex;
    flex-direction: column;
    justify-content: center;
}    
    @media (min-width: 992px) {
    
        .container-resource-select-screens{
            width:99vw;
         display: flex;
         flex-direction: column;
         align-content: center;
         align-items: center;
        }
        .container-resource-info{
            margin-left: 50px;
            }    
        .container-resource-1 {
            flex-direction: row;
            justify-content:space-around;
        }
            
        .container-resource-2 {
            flex-direction: row;
            justify-content:space-around;
        } 
        .container-resource-3 {
            flex-direction: row;
        } 
        .container-resource-4 {
            flex-direction: row;
        } 
        .container-resource-5 {
            flex-direction: row;
            justify-content:space-around;
        } 
        .container-resource-6 {
            flex-direction: row;
            justify-content:space-around;
        } 
        .container-resource-7 {
            flex-direction: row;
            justify-content:space-around;
        } 
    }