/* AlphabetBar.css */

.alphabet-bar {
    overflow-x: auto;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 16px 8px;
    background-color: #153d77;
    box-shadow: 15px 15px 15px rgb(69, 106, 149) !important;
    border: 1px solid rgb(69, 106, 149);
    border-radius: 5px;
 width: 100vw;
  }
  
  .alphabet-bar::-webkit-scrollbar {
    height: 6px;
  }
  
  .alphabet-bar::-webkit-scrollbar-thumb {
    background-color: #1976d2;
    border-radius: 3px;
  }
  
  .alphabet-bar {
    scrollbar-width: thin;
    scrollbar-color: #1976d2 #f5f5f5;
  }
  
  .alphabet-button {
    min-width: 50px;
    color: #1976d2;
    border: 1px solid #1976d2;
    margin: 0 4px;
    padding: 8px 0;
    background-color: #fff;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease;
    border-radius: 5px;
  }
  
  .alphabet-button:hover {
    background-color:   #1976d2;
    color: #e3f2fd ;
    border: 1px solid #e3f2fd ;
    transform: scale(1.1);
  }

  /*Escritorio*/
  @media (min-width: 1515px) {

    .alphabet-bar {
      justify-content: center;  
    }
  }