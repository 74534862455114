/********Tabla General************/



.header-table-flota-home{

    height: 40.5px;
    background-color: #153d77;
    color: white;
    text-align: center;
    padding-top:5px;
    padding-bottom:5px;
    font-size: 18px;
    font-weight: lighter;
    font-family: Roboto;
    width: 100% ;
    }
.container-table-flota-home{
    margin-right: 10px;
    margin-left:0px;
    margin-top: 10px;
    border-radius: 5px;
    width: 100% ;
    height: 350px;
    box-sizing: border-box; /* Asegura que el padding esté incluido en el ancho total */
}
.text-title-column-flota-home {
    color: #fff !important;
}
.table-flota-home{
width: 100% !important;
height: 100% !important;
border-radius: 5px;
}
.table-info-flota-home{
    width: 100%;
}

.table-info-flota-home tr:hover {
    background-color: #ff0707;
    /* Color de fondo cuando se hace hover */
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.table-info-flota-home tr:hover .MuiTableCell-root {
    color: #fff;
    /* Asegura que todas las celdas cambien de color con el hover */
    font-style: oblique;
}

/* Excluye la fila total del hover */
.table-info-flota-home tr.no-hover:hover {
    background-color: #153d77;
    color: inherit;
    /* Mantén el color del texto predeterminado */
    cursor: auto;
}

.no-hover-title-column-flota-home:hover {
    cursor: auto;
}

.table-info-flota-home thead tr {
    background-color: #1954ac;
    ;
    /* No cambia el fondo del encabezado */
    color: inherit;
    /* Mantén el color del texto original del encabezado */
}
.table-info-flota-home thead tr:hover {
    background-color: #1954ac;
    ;
    /* No cambia el fondo del encabezado */
    color: inherit;
    /* Mantén el color del texto original del encabezado */
}


.table-total-flota-home{
    background-color: #153d77;
    }
.table-total-text-flota-home{
    color: white !important;
} 
.table-info-flota-home .MuiTableRow-root {
    height: 51.5px; /* Ajusta este valor según la altura deseada */
}

/* Ajusta el padding dentro de las celdas para modificar la altura de las filas */
.table-info-flota-home .MuiTableCell-root {
    padding: 0px 15px 0px 15px; /* Ajusta este valor según el padding deseado */



    
}

@media (min-width: 1070px) {
    .container-table-flota-home{
        width: 58vw !important;
        margin-left: 0px;
    }
    .table-flota-home{
        width: 58vw !important;
        border-radius: 5px;
        }
        .table-info-flota-home{
            width: 58vw !important;
        }
  }
  
/*Version Tablet*/
@media (min-width: 1118px) {
    .container-table-flota-home{
        width: 60vw !important;
        margin-left: 0px;
    }
    .table-flota-home{
        width: 60vw !important;
        border-radius: 5px;
        }
        .table-info-flota-home{
            width: 60vw !important;
        }
  }

  /*Version Tablet*/
@media (min-width: 1277px) {
    .container-table-flota-home{
        width: 64vw !important;
        width: 64vw !important;
    }
    .table-flota-home{
        width: 64vw !important;
        border-radius: 5px;
        }
        .table-info-flota-home{
            width: 64vw !important;
        }
  }
  /**********************************************Escritorio*************************************************/
  /*************Navbar*********************/



  
  @media (min-width: 1500px) {
    .container-table-flota-home{
        width: 33vw !important;
        margin-left: 10px;
        width: 33vw !important;
    }
    .table-flota-home{
        width: 33vw !important;
        border-radius: 5px;
        }
        .table-info-flota-home{
            width: 33vw !important;
        }
  }
  
    
