/*General*/

@font-face {
  font-family: 'OpenSans';
  src: url('./assets/fonts/OpenSans-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/roboto/Roboto/Roboto-Italic.ttf') format('truetype');
}

* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box; /* Evita desbordamientos inesperados */
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width:  100vw;
  min-height: 100vh;
  max-width: 100vw;
  min-width: 100vw;
  /* Establece la altura del cuerpo como el 100% del viewport height */
  background: linear-gradient(to bottom, #153d77 33%, #fff);
  /* Crea un gradiente lineal */
  overflow-x: hidden; /*Eliminamos el scrol horizontal */
}

#root {
  height: auto;
  width: auto;
  max-width: 100vw;
  min-width: 100vw;
  /* Asegúrate de que el elemento raíz tenga una altura del 100% */
}

