/*****Loader******/

.container-loader {
    width: 100%;
    display: flex;
    align-items:self-start;
    justify-content: center;
    min-height: 70vh;
  }
  
  /* HTML: <div class="loader"></div> */
  .loader {
    width: 70px;
    aspect-ratio: 1;
    background:
      radial-gradient(farthest-side, #ff0000 90%, #0000) center/16px 16px,
      radial-gradient(farthest-side, rgb(11, 231, 11) 90%, #0000) bottom/12px 12px;
    background-repeat: no-repeat;
    animation: l17 1s infinite linear;
    position: relative;
  }
  
  .loader::before {
    content: "";
    position: absolute;
    width: 8px;
    aspect-ratio: 1;
    inset: auto 0 16px;
    margin: auto;
    background: #ffd900;
    border-radius: 50%;
    transform-origin: 50% calc(100% + 10px);
    animation: inherit;
    animation-duration: 0.5s;
  }
  
  @keyframes l17 {
    100% {
      transform: rotate(1turn)
    }
  }
  
  /* HTML: <div class="loader"></div> */
  .loader_progres {
    width: 30px;
    aspect-ratio: 1;
    background: #008cff;
    box-shadow: 0 0 60px 15px #fcffff;
    transform: translate(-90px);
    clip-path: inset(0);
    animation:
      l4-1 0.5s ease-in-out infinite alternate,
      l4-2 1s ease-in-out infinite;
  }
  
  @keyframes l4-1 {
    100% {
      transform: translateX(145px)
    }
  }
  
  @keyframes l4-2 {
    33% {
      clip-path: inset(0 0 0 -100px)
    }
  
    50% {
      clip-path: inset(0 0 0 0)
    }
  
    83% {
      clip-path: inset(0 -100px 0 0)
    }
  }
  
  .loader-text {
    font-size: 25px;
    font-weight: lighter;
    font-family: Roboto;
    text-align: start;
    color: white;
  }