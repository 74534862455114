/***************Mapa**************/

.container-map-general {
  max-width: 100%;
  overflow: hidden;
  padding-bottom: 10px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid white;
  width: 100%;
}


.map-general {
  height:
    580px !important;

}


.container-other-region {
  display: flex;
  padding-left: 5px;
  align-items: center;
  align-content: center;
  margin-bottom: 10px;
  margin-left: 10px;
  max-width: 65%;
  transition: 0.5s all ease;
  border-radius: 0px 20px 20px 0px;
  border-left: 2px solid #153d77;
  color: blue;
  cursor: pointer;
}

.container-other-region:hover {
  padding-left: 15px;
  width: 75%;
  background: linear-gradient(to right, #ff0000, #fa005c, #e4542c);
  box-shadow: 5px 5px 5px 5px rgb(69, 106, 149);
  border-left: 2px solid #fff;
  color: #fff;
  cursor: pointer;
}

.select-container-other-region {
  margin-left: 10px;
  align-items: center;
  align-content: center;
  display: flex;
  padding-left: 15px;
  width: 65%;
  background: linear-gradient(to right, #153d77, #436dad, #769acf);
  box-shadow: 5px 5px 5px 5px rgb(69, 106, 149);
  border-left: 2px solid #fff;
  color: #fff;
  border-radius: 0px 20px 20px 0px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: 0.5s all ease;
}

.select-container-other-region:hover {

  background: linear-gradient(to right, #ff0000, #fa005c, #e4542c);
  box-shadow: 5px 5px 5px 5px rgb(69, 106, 149);
  border-left: 2px solid #fff;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;

}

.text-other-region {
  margin-left: 5px;
}

.logo-other-region {
  color: green;
}

.title-map {
  width: 100%;
  background-color: #153d77;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-weight: lighter;
  font-family: Roboto;
  border-radius: 4px 4px 0px 0px;
}

/**********************************************Escritorio*************************************************/
@media (min-width: 1070px) {
  /*mapa General*/

  .container-map-general {
    margin-right: 10px;
    width: 355px;
    height: 710px !important;
    margin-top: 10px;
    margin-left: 10px;

  }

}

/**********************************************Escritorio*************************************************/
@media (min-width: 1500px) {
  /*mapa General*/

  .container-map-general {
    height: 680px !important;
  }


}