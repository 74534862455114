.container-chart-general-flota-region-emb{
 
    background-color: white;
    box-shadow: 15px 15px 15px rgb(69, 106, 149);
    border: 1px solid rgb(69, 106, 149);
    border-radius: 5px;
    height: 475px !important;
    width:auto !important;
    margin-top: 10px;
    margin-right: 0px;
    box-sizing: border-box; /* Asegura que el padding esté incluido en el ancho total */
}
.title-text-chart-categoria-emb{
  height: 76px !important;
  background-color: #153d77;
  color: white;
  text-align: center;
  padding-top:5px;
  padding-bottom:5px;
  font-size: 18px;
  font-weight: lighter;
  font-family: Roboto;
}

/**********************************************Escritorio*************************************************/
/*************Navbar*********************/
@media (min-width: 992px) {
  .container-chart-general-flota-region-emb{
    width: 35.9vw !important;
    margin-right: 15px;
}

  }