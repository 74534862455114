.footer {
  background-color: #0D47A1;
  color: white;
  padding: 20px;
  text-align: center;
  margin-top: 50px; /* Margen superior del footer, ajústalo si es necesario */
  /* Posicionamiento */
  position: relative; /* Cambia a `fixed` si lo deseas siempre visible en la parte inferior */
  bottom: 0;
  width: 100%;
  max-width: 100vw; /* Asegura que no supere el ancho del viewport */
  overflow-x: hidden; /* Evita que los hijos lo hagan crecer */
  box-sizing: border-box; /* Asegura que padding y border no sumen extra ancho */
}

