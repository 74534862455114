/* AlphabetBar.css */

.flota-bar {
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 16px 8px;
    background-color: #153d77;
    box-shadow: 15px 15px 15px rgb(69, 106, 149) !important;
    border: 1px solid rgb(69, 106, 149);
    border-radius: 5px;
  }
  
  .flota-bar::-webkit-scrollbar {
    height: 6px;
  }
  
  .flota-bar::-webkit-scrollbar-thumb {
    background-color: #1976d2;
    border-radius: 3px;
  }
  
  .flota-bar {
    scrollbar-width: thin;
    scrollbar-color: #1976d2 #f5f5f5;
  }
  
  .flota-button {
    min-width: 100px;
    color: #1976d2;
    border: 1px solid #1976d2;
    margin: 0 4px;
    padding: 8px 0;
    background-color: #fff;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease;
    border-radius: 5px;
  }
  
  .flota-button:hover {
    background-color:   #1976d2;
    color: #e3f2fd ;
    border: 1px solid #e3f2fd ;
    transform: scale(1.1);
  }
  