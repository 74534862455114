/***********************************
* ESTILOS GENERALES
************************************/
/* {
  border: 1px solid red !important;
}*/
* {
  margin: 0px;
  padding: 0px;
}

.box-style {
  padding: 20px 20px;
  border-radius: 12px;
  color: white;
  animation: fadeIn 1s ease;
  box-shadow: 15px 15px 15px rgb(69, 106, 149);
  border: 1px solid rgb(69, 106, 149);
  border-radius: 5px;
  background-color: #153d77;
}

.home-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  min-width: 100vw;
  max-width: 100vw;
  font-family: 'Roboto', sans-serif;
}

.home-screen__intro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
}

.home-screen__intro-title {
  font-size: 1.8rem;
  margin-bottom: 20px;
  font-weight: lighter;
}

.home-screen__intro-subtitle {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.home-screen__demo-content {
  width: 100%;
  /* Mejor que 100vw para evitar desbordamientos */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Centra horizontalmente */
  align-items: center;
  /* Centra verticalmente */
}

.home-screen__demo {
  width: 100%;
  /* En lugar de 100vw para evitar desbordamientos */
  display: flex;
  flex-direction: column;
  /* Para que los hijos se apilen verticalmente */
  align-items: center;
  min-width: 100%;
  /* En lugar de 100vw */
}

.home-screen__demo-container {
  margin-top: 40px;
  background-color: #153d77;
  text-align: center;
  box-shadow: 15px 15px 15px rgb(69, 106, 149);
  /*border: 1px solid rgb(69, 106, 149);   Este se debe dejar cuando se cree desktop*/
  border-radius: 5px;
  z-index: 1;
  /* Asegúrate de que el contenedor no tenga un z-index mayor que el del menú flotante */
  position: relative;
  width: 100vw;
  max-width: 100vw;
  padding-bottom: 20px;
  padding-top: 20px;
}

.home-screen__demo-title h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  font-weight: lighter;
  color: #fff;
}

.home-screen__charts-group {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.ChartLineFlota-Home-desktop {
  display: none;
}

.ChartLineFlota-Home-table {
  width: 100%;
  /* Mejor que 100vw para evitar desbordamientos */
  display: flex;
  justify-content: center;
  /* Centra horizontalmente */
  align-items: center;
  /* Centra verticalmente */
}

.container-home-1 {
  display: flex;
  flex-direction: column;
  margin-top: 10px;

}

.container-home-2 {
  display: flex;
  flex-direction: column;
}

/* Overview Section */

.overview {
  text-align: center;
  box-shadow: 15px 15px 15px rgb(69, 106, 149);
  border: 1px solid rgb(69, 106, 149);
  border-radius: 5px;
  width: 50vw;
  background-color: #153d77;
  height: auto;
  animation: fadeIn 1.2s ease;
  width: 100%;

}

.data-insights {

  margin-top: 40px;
  background-color: #f0f4f8;
  text-align: center;
  box-shadow: 15px 15px 15px rgb(69, 106, 149);
  border: 1px solid rgb(69, 106, 149);
  border-radius: 5px;
  width: 50vw;
  background-color: #153d77;
  width: 100%;
}

.overview h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  font-weight: lighter;
  color: #fff;
  margin-top: 20px;
}

.overview p {
  font-size: 1.1rem;
  margin-bottom: 20px;
  font-weight: lighter;
  color: #fff;
}

.overview-list {
  list-style-type: none;
  padding: 0;
}

.overview-list li {
  font-weight: lighter;
  color: #fff;
  margin-bottom: 10px;
}

/* Data Insights Section */

.data-insights h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  font-weight: lighter;
  color: #fff;
}

.insight-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

}

.insight-card {
  background: white;
  padding: 20px;
  max-width: 300px;
  margin: 20px;
  box-shadow: 15px 15px 15px rgb(69, 106, 149);
  border: 1px solid rgb(69, 106, 149);
  border-radius: 5px;
  transition: transform 1s ease;
}

.insight-card:hover {
  transform: translateY(-30px);
}

.insight-card h3 {
  font-size: 1.5rem;
  color: #1e90ff;
  margin-bottom: 10px;
}

.insight-card p {
  font-size: 1rem;
  color: #666;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}



/***********************************
* TABLET (>= 768px)
************************************/
@media (min-width: 1070px) {

  .home-screen__intro-title {
    align-items: flex-start;
    width: 70%;
  }
  .home-screen__intro {
    width: 70%;

  }

  .home-screen__demo-container {

    padding-bottom: 20px;
  }

  .home-screen__demo-content {
    flex-direction: row;
  }



  /* Aún se oculta el desktop chart en tablets */
  .ChartLineFlota-Home-desktop {
    display: none;
  }

  .ChartLineFlota-Home-table {
    display: flex;
  }

  .home-screen__charts-group {
    width: auto;
  }
}

/***********************************
* DESKTOP (>= 1500px)
************************************/
@media (min-width: 1500px) {
  .home-screen__intro-title {
    align-items: flex-start;
    width: 60%;
  }
  .home-screen__intro {
    width: 65%;

  }

  .home-screen__demo-content {
    flex-direction: row;

  }

  .home-screen__charts-group {
    flex-direction: row;

  }



  /* Mostrar el chart de desktop */
  .ChartLineFlota-Home-desktop {
    display: flex;
    
  }

  /* Ocultar el chart de tablet en desktop */
  .ChartLineFlota-Home-table {
    display: none;
  }

  .home-screen__intro-title {
    align-items: flex-start;
    width:65%;
  }

}