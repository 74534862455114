.container-chart-general-line {
  background-color: white;
  border-radius: 5px;
  padding-left: 5px;
  padding-top: 10px;
  width: 100vw;
  height: 383px;
  margin-top: 10px;

}
.chart-general{
  color: green;
}






/*Version Tablet*/
@media (min-width: 1070px) {
  .container-chart-general-line {
    width: calc(58vw + 360px);
    height: 310px;


  }
}
@media (min-width: 1118px) {
  .container-chart-general-line {
    width: calc(60vw + 360px);
    height: 310px;


  }
}
/*Version Tablet*/
@media (min-width: 1277px) {
  .container-chart-general-line {
    width: calc(64.2vw + 360px);
    height: 310px;


  }
}
/**********************************************Escritorio*************************************************/
/*************Navbar*********************/
@media (min-width: 1500px) {
  .container-chart-general-line {
    width: calc(66.25vw + 5px);
    height: 310px;


  }
}




