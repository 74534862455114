/*header drawer*/
.container-header-drawer {
    width: 100vw;
    padding: 10px 10px 10px 10px;
    background: linear-gradient(to right, #153d77, #6691d0, #6691d0, #153d77);
    display: flex;
    align-items: center;
    align-content: center;
  }
  
  .container-title-drawer {
    padding: 10px 10px 10px 10px;
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
  }
  
  .offcanvas-header {
    padding: 0%;
  }
  
  .close {
    font-size: 22px;
    padding-right: 20px;
    color: white;
    font-weight: lighter;
  }
  
  h4.close:hover {
    cursor: pointer;
    font-weight: bold;
  }
  
  .link-logo-drawer {
    height: 40px;
    width: 40px;
  }
  
  .text-logo-drawer {
    font-size: 20px;
    font-weight: lighter;
    color: #fff;
    font-family: Roboto;
  }
  
  /*body drawer*/
  .continer-drawer {
    padding-top: 10px;
    margin-left: 20px;
  
  }

  .container-item-menu {
    display: flex;
    align-items: center;
    align-content: center;
    margin-top: 30px;
    margin-left: 10px;
    padding: 8px;
    width: 70%;
    transition: 0.5s all ease;
    border-radius: 0px 20px 20px 0px;
    border-left: 2px solid #153d77;
  }
  
  .container-item-menu:hover {
    padding-left: 30px;
    width: 75%;
    background: linear-gradient(to right, #153d77, #6691d0, #abc5ec);
    box-shadow: 5px 5px 5px 5px rgb(69, 106, 149);
  }
  
  .container-item-menu:hover .text-item-menu,
  .container-item-menu:hover .logo-item-menu {
    color: white;
    /* Cambia el color del texto y del ícono cuando .container-item-menu está en estado :hover */
  }
  
  .text-item-menu {
    margin: 0% 0% 0% 10px;
    font-family: Roboto;
    font-size: 20px;
    color: #153d77;
  }
  
  .logo-item-menu {
    font-size: 34px;
    color: #153d77;
  
  }
    /**********************************************Tablet*************************************************/
  @media (min-width: 576px) {
    .container-header-drawer {
      width: 50vw;
    }  

    .container-item-menu:hover {
      padding-left: 30px;
      width: 75%;
    }
  }

    /**********************************************Escritorio*************************************************/
    @media (min-width: 992px) {
      .container-header-drawer {
        width: 40vw;
    
      }
      .container-item-menu:hover {
        padding-left: 30px;
        width: 85%;
      }
      
    }

  /**********************************************Escritorio width*************************************************/

@media (min-width: 1200px) {
  .container-header-drawer {
    width: 30vw;

  }
  .container-item-menu:hover {
    padding-left: 30px;
    width: 85%;
  }
  

}
  /**********************************************Escritorio utra width*************************************************/

  @media (min-width: 1600px) {
    .container-header-drawer {
      width: 25vw;
  
    }
    .container-item-menu:hover {
      padding-left: 30px;
      width: 85%;
    }
    
  
  }