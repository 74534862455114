.view-container{

display: flex;
align-content: center;
align-items: center;
max-width: 100vw;
}

@media (min-width: 992px) {

    .view-container{
        display: flex;
 
    }
    
}