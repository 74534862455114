.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%; /* O ajusta según tus necesidades */
  max-height: 80%; /* Limita la altura máxima del popup */
  overflow: hidden; /* Evita el desbordamiento del contenido */
  display: flex;
  flex-direction: column;
}

.response-ia {
  flex: 1; /* Permite que este contenedor tome el espacio disponible */
  overflow-y: auto; /* Habilita el scroll vertical */
  margin: 10px 0; /* Añade margen superior e inferior */
}

/* Botón de abrir */
.open-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

/* Botón de cerrar */
.close-btn {
  padding: 10px 20px;
  background-color: #ff6347;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

/* Transición suave */
.popup-overlay,
.popup-content {
  transition: all 0.3s ease;
}

.loader2 {
  width: 60px;
  height: 60px;
  display: flex;
  color: #FC3A51;
  --c:#0000 calc(100% - 20px),currentColor calc(100% - 19px) 98%,#0000;
  background:
    radial-gradient(farthest-side at left,var(--c)) right /50% 100%,
    radial-gradient(farthest-side at top ,var(--c)) bottom/100% 50%;
  background-repeat: no-repeat;
  animation: l18-0 2s infinite linear .25s;
}
.loader2::before{
  content:"";
  width:50%;
  height: 50%;
  background:radial-gradient(farthest-side at bottom right,var(--c));
  animation: l18-1 .5s infinite linear;
}
@keyframes l18-0 {
  0%   ,12.49% {transform: rotate(0deg)}
  12.5%,37.49% {transform: rotate(90deg)}
  37.5%,62.49% {transform: rotate(180deg)}
  62.5%,87.49% {transform: rotate(270deg)}
  87.5%,100%   {transform: rotate(360deg)}
}
@keyframes l18-1 {
  0%      {transform: perspective(150px) rotateY(0)      rotate(0)} 
  50%     {transform: perspective(150px) rotateY(180deg) rotate(0)} 
  80%,100%{transform: perspective(150px) rotateY(180deg) rotate(90deg)}
}