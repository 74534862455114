.container-page-construction {
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 40px;
    width: 100%;
}

.construction-ico {
    font-size: 100px;
    color: #1976d2;
    margin-bottom: 16px;
}

.container-page-contrution-2 {
    background-color: #fff;
    box-shadow: 15px 15px 15px rgb(69, 106, 149);
    border: 1px solid rgb(69, 106, 149);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 40vh;
    padding-top: 30px;
}
