
.icon-floating-menu-delete-home {
  cursor: pointer;
  color: blue; /* Cambia el color según prefieras */
}

.icon-floating-menu-delete-home:hover {
  color: red !important; /* Color al pasar el mouse */
}




