.list-resource-paper {
    padding: 16px;
    margin: 16px;
    box-shadow: 15px 15px 15px rgb(69, 106, 149) !important;
    border-radius: 5px !important;
    border: 1px solid rgb(69, 106, 149);

    background-color: rgb(69, 106, 149) !important;
}

.list-resource-title {
    margin-bottom: 12px; /* Espacio inferior para el título */
    color: #333; /* Color del texto */
    background-color: #153d77;
    box-shadow: 15px 15px 15px rgb(69, 106, 149) !important;
    border: 1px solid rgb(69, 106, 149);
    border-radius: 5px;
    color: #fff;
    padding: 5px;

}

.list-resource-paper .species-item {
    padding: 16px;
    margin: 4px;
    border-radius: 8px;
    background-color: #fff ; /* Color de fondo para cada especie */
    cursor: pointer; /* Cambia el cursor al pasar el mouse */
    text-align: center; /* Centrar el texto dentro del Paper */
    transition: transform 0.3s ease, background-color 0.3s ease;
    border: 1px solid #1976d2;
    color: #1976d2;
}

.list-resource-paper .species-item:hover {
    background-color: #1976d2;
    color: #e3f2fd;
    transform: scale(1.03);
    border: 1px solid #e3f2fd ;
}

.container-list-resource{
    margin-top: 10px !important;
}
.highlighted-letter {
    background-color: red;
    color: white;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 20px;         /* Ajusta el tamaño de la fuente */
    line-height: 1.1;        /* Ajusta la altura de línea para centrar */
    margin-left: 5px;
}
.no-resource-letter{
    color: #fff;
}
