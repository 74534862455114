.container-chart-general-flota{
   display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 5px;
    height: 350px;
    width:100% !important;
    margin-top: 10px;
    margin-left: 0px;
    box-sizing: border-box; /* Asegura que el padding esté incluido en el ancho total */
}

/*Version Tablet*/

@media (min-width: 1070px) {
  .container-chart-general-flota{ 
    width: 58vw !important;
 }
}
@media (min-width: 1118px) {
  .container-chart-general-flota{ 
    width: 60vw !important;
 }
}
/*Version Tablet*/
@media (min-width: 1277px) {
  .container-chart-general-flota{ 
    width: 64vw !important;
 }
}
/**********************************************Escritorio*************************************************/
/*************Navbar*********************/
@media (min-width: 1500px) {
  .container-chart-general-flota{
    width: 33vw !important;
}

  }