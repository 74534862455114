.container-chart-general-line-region {
  background-color: white;
  box-shadow: 15px 15px 15px rgb(69, 106, 149);
  border: 1px solid rgb(69, 106, 149);
  border-radius: 5px;
  width:auto;
  height: 350px;
  margin-top: 10px;

}
.chart-general-region{
  color: green;
}
.title-text-chart-year{
  height: 38px;
  background-color: #153d77;
  color: white;
  text-align: center;
  padding-top:5px;
  padding-bottom:5px;
  font-size: 18px;
  font-weight: lighter;
  font-family: Roboto;
}
/**********************************************Escritorio*************************************************/

@media (min-width: 992px) {
  .container-chart-general-line-region {
    width: 73.6vw;
    height: 400px;
  }
}
