.icon-floating-menu-delete-home {
  cursor: pointer;
  color: blue;
  /* Cambia el color según prefieras */
}

.icon-floating-menu-delete-home:hover {
  color: red !important;
  /* Color al pasar el mouse */
}

.text-ia {
  margin-left: 5px;
}

.floating-menu-region {
  margin-top: 150px;
}

/* ===== MEDIA QUERIES SOLO CAMBIOS EN 992PX O MÁS ===== */
@media (min-width: 992px) {
  .floating-menu-region {
    margin-top: 0px;
  }
}