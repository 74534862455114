.floating-menu {
  background-color: white;
  box-shadow: 15px 15px 15px rgb(69, 106, 149);
  border: 1px solid rgb(69, 106, 149);
  border-radius: 5px;
  margin-top: 200px;
  margin-left: 10px;
  position: fixed;
  top: 10px;  /* Espacio desde el borde superior */
  left: 10px; /* Espacio desde el borde izquierdo */
  z-index: 999;
  width: 10vw;
  padding: 10px;
}
.container-title-menu-floating{
  background-color: #153d77;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: lighter;
  font-family: Roboto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

td {
  padding: 8px;
  border: 1px solid #ddd;
}

td:nth-child(2) {
  text-align: center;
}

.floating-item-menu h5 {
  margin: 0;
}

.icon-floating-menu-delete {
  cursor: pointer;
  color: blue; /* Cambia el color según prefieras */
}

.icon-floating-menu-delete:hover {
  color: red; /* Color al pasar el mouse */
}

.container-button{
  display: flex;
  align-items: center;
  align-content: center;
  margin-top: 20px;
  margin-left: 0px;
  padding: 8px;
  width: 70%;
  transition: 0.5s all ease;
  border-radius: 0px 20px 20px 0px;
  border-left: 2px solid #153d77;
  background: linear-gradient(to right, #153d77, #6691d0, #abc5ec);
  color: white;
}
.container-button:hover {
  padding-left: 30px;
  width: 75%;
  background: linear-gradient(to right, #c01c1c, #f93e3e, #ecabab);
  box-shadow: 5px 5px 5px 5px rgb(69, 106, 149);
  cursor: pointer;
  border-left: 2px solid #ff0000;
}

