/***************Mapa**************/

.container-map-region-select{
  background-color: white;
  box-shadow: 15px 15px 15px rgb(69, 106, 149);
  border: 1px solid rgb(69, 106, 149);
  width: 100%;
  border-radius: 5px;
  margin-top: 50px;
}


.map-region-select {
  height: 500px !important;
  pointer-events: none;
}



.select-container-other-region-select {
  margin-top: 10px;
  align-items: center;
  align-content: center;
  display: flex;
  padding-left: 5px;
  width: 65%;
  background: linear-gradient(to right, #153d77, #436dad, #769acf);
  box-shadow: 5px 5px 5px 5px rgb(69, 106, 149);
  border-left: 2px solid #fff;
  color: #fff;
  border-radius: 0px 20px 20px 0px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: 0.5s all ease;
}

.select-container-other-region-select:hover {
  padding-left: 15px;
  width: 75%;
  background: linear-gradient(to right, #ff0000, #fa005c, #e4542c);
  box-shadow: 5px 5px 5px 5px rgb(69, 106, 149);
  border-left: 2px solid #fff;
  color: #fff;
  cursor: pointer;

}

.text-other-region-select {
  margin-left: 10px;
  text-align: center;
  font-family: Roboto;
  font-size: 21px;
  font-weight: lighter;
  font-family: Roboto;

}

.logo-other-region-select {
  color: green;
}

.title-map {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 5px;
  background-color: #153d77;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-weight: lighter;
  font-family: Roboto;
}
.logo-no-map{
  font-size: 200px;
  color:#153d77
}
.container-no-map{
  height: 600px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/**********************************************Escritorio*************************************************/
@media (min-width: 992px) {

  /*mapa General*/
  .map-general {
    height: 600px !important;

  }



  .container-map-region-select{
    width: 31.7vw;
    margin-top: 0px;
  }


}