/* Estilos para el contenedor de login */


.login-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo semi-transparente */
    z-index: 1000; /* Asegurar que esté por encima del contenido */
  }
  
  /* Estilos para la caja de login */
  .login-box {
    position: relative;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    max-width: 500px;
    width: 100%;
    box-shadow: 15px 15px 15px rgb(69, 106, 149);
    border: 1px solid rgb(69, 106, 149);
    border-radius: 5px;
  }
  .container-login-input{
    margin: 40px;
  }
/*estilo titulo*/
.container-login-title
{
  display: flex;
  width: 100%;
  align-content: center;
  background-color: #153d77;
  color: #fff;
  border-radius: 3.6px 3.6px 0px 0px;
  border: 1px solid rgb(69, 106, 149);
}

.container-login-title-text{
  width: 100%;
  align-items: center;
  justify-content: center;
  align-content: center;
  justify-items: center;
  margin-left: 70px;
}
.title-login{
  font-size: 25px !important;
  margin: 0px !important;
  text-align: center;
  font-family: Roboto;
}
  /* Estilos para el botón de cerrar */
  .close-button {
    position: absolute;
    color: #888;
    background-color: #153d77;
    cursor: pointer;
    transition: color 0.3s ease;
    color: #fff !important;
    width: 50px;
    margin: 10px !important;
  }
  
  .close-button-icon:hover {
    color: #f44336; /* Color rojo al pasar el ratón */
    background-color: #153d77;
  }
  
  .container-login-button{
    display: flex;
    justify-content: center;
  }
  /* Botón de iniciar sesión */
  .login-button {
    transition: 0.5s all ease !important;
    margin-top: 16px;
    padding: 10px;
    background-color: #153d77;
    width: 60% !important;
    border-radius: 15px !important;

  }
  
  .login-button:hover {
    background-color: red !important; /* Efecto hover */
    width: 90% !important;
  }
  