/********Tabla General************/



.header-table-region-pescador-categoria_agrupada{
    height: 72px !important;
    background-color: #153d77;
    color: white;
    text-align: center;
    padding-top:5px;
    padding-bottom:5px;
    font-size: 18px;
    font-weight: lighter;
    font-family: Roboto;
    }

   .heade-table-categoria_agrupada{
    width: 100%!important;
   } 


.table-region-pescador-categoria_agrupada{
    box-shadow: 15px 15px 15px rgb(69, 106, 149) !important;
    border: 1px solid rgb(69, 106, 149);
    margin-left:0px;
    margin-top: 10px;
    border-radius: 5px !important;
    height: 475px;
    box-sizing: border-box; /* Asegura que el padding esté incluido en el ancho total */
    text-align: right !important;

}


.table-region-pescador-categoria_agrupada thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white; /* O el color de fondo que desees */
  }

.table-info-region-pescador-categoria_agrupada{
    width: 100%;

}
.title-column-categoria_agrupada{
    background-color: #1954ac;
    height: 50px !important;
}
.text-title-column-categoria_agrupada{
    color: #fff !important;
}
.table-total-region-year-categoria_agrupada{
    background-color: #153d77;
    }
.table-total-text-region-year-categoria_agrupada{
    color: white !important;
    text-align: right !important;
} 
.table-info-categoria_agrupada .MuiTableRow-root {
    height: 50px; /* Ajusta este valor según la altura deseada */
}

/* Ajusta el padding dentro de las celdas para modificar la altura de las filas */
.table-info-region-pescador-categoria_agrupada .MuiTableCell-root {
    padding: 0px 15px 0px 15px; /* Ajusta este valor según el padding deseado */
}
/**********************************************Escritorio*************************************************/
@media (min-width: 992px) {

    .table-region-pescador-categoria_agrupada{
        width: 38.8vw !important;
        border-radius: 5px;
        margin-left: 30px;
        }
        .table-info-region-pescador-categoria_agrupada{
            width: 100% !important;
        }
    }