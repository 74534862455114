/***************Mapa**************/

.container-map-resource {
  background-color: white;
  box-shadow: 15px 15px 15px rgb(69, 106, 149);
  border: 1px solid rgb(69, 106, 149);
  width: 100%;
  border-radius: 5px;
  margin-top: 50px;
}


.map-resource {
  height: 
  580px !important;

}


.container-other-resource{
  display: flex;
  padding-left: 5px;
  align-items: center;
  align-content: center;
  margin-bottom: 10px;
  margin-left: 10px;
  width: 65%;
  transition: 0.5s all ease;
  border-radius: 0px 20px 20px 0px;
  border-left: 2px solid #153d77;
  color: blue;
  cursor: pointer;
}

.container-other-resource:hover{
  padding-left: 15px;
  width:75%;
  background: linear-gradient(to right, #ff0000, #fa005c, #e4542c);
  box-shadow: 5px 5px 5px 5px rgb(69, 106, 149);
  border-left: 2px solid #fff;
  color: #fff;
  cursor: pointer;
}
.select-container-other-resource{
  margin-left: 10px;
  align-items: center;
  align-content: center;
  display: flex;
  padding-left: 15px;
  width:65%;
  background: linear-gradient(to right, #153d77, #436dad, #769acf);
  box-shadow: 5px 5px 5px 5px rgb(69, 106, 149);
  border-left: 2px solid #fff;
  color: #fff;
  border-radius: 0px 20px 20px 0px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: 0.5s all ease;
}

.select-container-other-resource:hover{

  background: linear-gradient(to right, #ff0000, #fa005c, #e4542c);
  box-shadow: 5px 5px 5px 5px rgb(69, 106, 149);
  border-left: 2px solid #fff;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;

}
.text-other-resource{
  margin-left: 5px;
}
.logo-other-resource{
  color: green;
}
.title-map{
  width: 100%;
  background-color: #153d77;
  color:#fff;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-weight: lighter;
  font-family: Roboto;
}
/**********************************************Escritorio*************************************************/
@media (min-width: 992px) {
/*mapa region*/
  .map-resource {
    height: 620px !important;
    
  }
  .container-map-resource {
    width: 400px !important;
    margin-top: 0px 
  }




}